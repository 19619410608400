"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuth = exports.AuthProvider = void 0;
var react_1 = __importStar(require("react"));
var jwt_decode_1 = require("jwt-decode");
var google_1 = require("@react-oauth/google");
var _constants_1 = require("../../constants");
// Initialize the context with a default value of `null`
var AuthContext = (0, react_1.createContext)(null);
// Create a provider component
var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = (0, react_1.useState)(null), userPictureLink = _c[0], setUserPictureLink = _c[1];
    var _d = (0, react_1.useState)(true), loading = _d[0], setLoading = _d[1]; // State for tracking loading of other context states (necessary for page loads)
    (0, react_1.useEffect)(function () {
        var token = localStorage.getItem("speedcart_auth_exists");
        if (token === "true") {
            setIsAuthenticated(true);
            setUserPictureLink(localStorage.getItem("userImageUrl"));
        }
        else {
            setIsAuthenticated(false);
        }
        setLoading(false); // Set loading to false after the status is determined
    }, []);
    var login = function (token) {
        var userInfo = (0, jwt_decode_1.jwtDecode)(JSON.parse(token).credential);
        // Initialize CSRF protection for the application
        fetch("".concat(_constants_1.BASE_URL, "/sanctum/csrf-cookie"), {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
        }).then(function (csrfResponse) {
            console.log("Response for CSRF token: ".concat(csrfResponse.status, " ").concat(csrfResponse.statusText, " and ").concat(csrfResponse.type)); // Get the CSRF token from cookies (document.cookies)
            //const csrfToken = getCookie("XSRF-TOKEN");
            //console.log(`csrf token retrieved: ${csrfToken}`);
            // Verify Google JWT with your backend
            fetch("".concat(_constants_1.BASE_URL, "/auth/google"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer ".concat(token),
                    'Accept': 'application/json',
                },
                credentials: "include",
            }).then(function (response) {
                //console.log(`Login response: ${JSON.stringify(response)}`);
                if (response.status === 200) {
                    setIsAuthenticated(true);
                    localStorage.setItem("speedcart_auth_bearer_token", token);
                    localStorage.setItem("speedcart_auth_exists", 'true');
                    localStorage.setItem("userImageUrl", userInfo.picture);
                }
                return response.json();
            })
                .then(function (data) {
                // Handle the response data here
                console.log("Response text: " + JSON.stringify(data) + " and data token: " + JSON.stringify(data.token));
                localStorage.setItem("speedcart_auth_bearer_token", JSON.stringify(data.token));
            })
                .catch(function (error) {
                // Handle errors here
                console.error("Error:", error);
            });
            setIsAuthenticated(true);
            setUserPictureLink(userInfo.picture);
        });
    };
    var logout = function () {
        // Handle google's logout flow first
        (0, google_1.googleLogout)();
        fetch("".concat(_constants_1.BASE_URL, "/auth/google"), {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
            // No Bearer token necessary (cookie contains JWT that will be deleted on backend)
        })
            .then(function () {
            localStorage.removeItem("speedcart_auth_exists");
            localStorage.removeItem("userImageUrl");
            setIsAuthenticated(false);
            setUserPictureLink(null);
        })
            .catch(function (error) {
            console.error("Error:", error);
        });
    };
    return react_1.default.createElement(AuthContext.Provider, { value: { isAuthenticated: isAuthenticated, loading: loading, userPictureLink: userPictureLink, login: login, logout: logout } }, children);
};
exports.AuthProvider = AuthProvider;
// Create a custom hook to use the AuthContext
var useAuth = function () {
    var context = (0, react_1.useContext)(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
exports.useAuth = useAuth;
