"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchOwnedShoppingLists = void 0;
var _constants_1 = require("../../constants");
var fetchOwnedShoppingLists = function () {
    return fetch("".concat(_constants_1.BASE_URL, "/shopping-lists"), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include' // Include cookies in the request
    });
};
exports.fetchOwnedShoppingLists = fetchOwnedShoppingLists;
